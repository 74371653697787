import React, { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import PropTypes from 'prop-types'
import {
  Button,
  ButtonType,
  FlagIcon,
  Icon,
  Select,
  Text
} from '@junglescout/edna'

import {
  REGIONS_LABELS,
  REGIONS_WITH_COUNTRIES,
  MARKETPLACES
} from 'constants/countries'
import { redirectToSellerCentralAuthForm } from 'helpers/amazon_seller_account'
import { sendSegmentMWSSyncEvent } from 'helpers/segment'

import { SellerAccountSyncIntro } from './common/SellerAccountSyncIntro'

const RegionSelectors = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 20px;
`
const RegionSelector = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 0;
  background-color: ${({ theme }) => theme.colors.grey050};
  border: 1px solid ${({ theme }) => theme.colors.grey100};
  border-radius: 8px;
  padding: ${({ padding }) => padding || '20px 30px'};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey075};
    border-color: ${({ theme }) => theme.colors.grey100};
  }

  ${props =>
    props.selected &&
    `&, &:hover {
      background-color: ${props.theme.colors.orange100}; 
      border-color: ${props.theme.colors.orange500};
    }
  `}
`
const FlagsWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 10px;
`
const MoreCountriesIcon = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.grey500};
  border-radius: 50%;
`
const StyledOption = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
const PrivacyNote = styled.div`
  padding: 12px 20px 20px;
  background-color: ${({ theme }) => theme.colors.grey050};
  border-radius: 8px;
`
const LockIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.grey300};
  margin-bottom: 8px;
`
const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.grey600};
  text-decoration: underline;
`

export const SellerAccountSync = ({
  connectCallback,
  introWidth,
  regionsPadding
}) => {
  const { t } = useTranslation(['account', 'mwsSync', 'generic'])

  const [selectedRegion, setSelectedRegion] = useState()
  const [amazonMarketplaceId, setAmazonMarketplaceId] = useState()

  const regions = {
    ...REGIONS_WITH_COUNTRIES,
    eu: ['FR', 'DE', 'UK', 'OTHER']
  }

  const { data, isLoading, isError, error } = useQuery([
    'api/junglekit/marketplaces'
  ])

  const selectedRegionMarketplaces = useMemo(
    () => data?.primary_marketplaces[selectedRegion?.toUpperCase()],
    [data?.primary_marketplaces, selectedRegion]
  )

  // The structure of marketplaces API response is:
  // {
  //   "primary_marketplaces": {
  //     "NA": [["US", "ATVPDKIKX0DER", "United States"], ...],
  //     "EU": [ ... ],
  //     ...
  //   }
  // }
  const getMarketplaceName = id =>
    selectedRegionMarketplaces?.filter(item => item[1] === id)[0][2]

  const handleLWAButtonClick = () => {
    if (connectCallback) {
      connectCallback()
    }

    sendSegmentMWSSyncEvent(
      'Connection started',
      selectedRegion,
      getMarketplaceName(amazonMarketplaceId)
    )

    redirectToSellerCentralAuthForm({
      region: selectedRegion,
      amazonMarketplaceId
    })
  }

  const renderMarketplaceOptions = () => {
    if (!data) {
      return null
    }

    return selectedRegionMarketplaces?.map(countryData => {
      const [countryCode, marketplaceId] = countryData
      const label = MARKETPLACES[countryCode.toLowerCase()].name

      return (
        <Select.Item key={countryCode} value={marketplaceId}>
          <StyledOption>
            <FlagIcon country={countryCode} size="20px" /> {label}
          </StyledOption>
        </Select.Item>
      )
    })
  }

  const handleMarketplaceChange = id => {
    sendSegmentMWSSyncEvent(
      'Marketplace chosen',
      selectedRegion,
      getMarketplaceName(id)
    )
    setAmazonMarketplaceId(id)
  }

  return (
    <div>
      <SellerAccountSyncIntro width={introWidth} />
      <Text variant="headingSm" element="div" margin="20px 0 0 0">
        {t(
          'account:SellerAccountSync.modal.selectRegion',
          'Which region are you selling in?'
        )}
      </Text>
      <Text color="grey600" element="div" margin="0 0 10px">
        {t('account:SellerAccountSync.modal.selectOne', 'Select only one')}
      </Text>
      <RegionSelectors>
        {Object.entries(regions).map(([regionCode, countries]) => (
          <RegionSelector
            key={regionCode}
            onClick={() => setSelectedRegion(regionCode)}
            padding={regionsPadding}
            selected={selectedRegion === regionCode}>
            <FlagsWrapper>
              {countries.map(country =>
                country === 'OTHER' ? (
                  <MoreCountriesIcon
                    key={country}
                    variant="headingXs"
                    element="div"
                    color="white">
                    +13
                  </MoreCountriesIcon>
                ) : (
                  <FlagIcon key={country} country={country} size="24px" />
                )
              )}
            </FlagsWrapper>
            <Text align="center" variant="bodyMd" element="h4" fontWeight={500}>
              {REGIONS_LABELS[regionCode]}
            </Text>
          </RegionSelector>
        ))}
      </RegionSelectors>
      {selectedRegion && (
        <Select
          disabled={isLoading || isError}
          error={error}
          label={t(
            'account:SellerAccountSync.modal.selectMarketplace',
            'Select your primary marketplace'
          )}
          onValueChange={handleMarketplaceChange}
          placeholder={t('generic:Select', 'Select')}
          width={introWidth ? '100%' : '523px'}>
          {renderMarketplaceOptions()}
        </Select>
      )}
      <Button
        onClick={handleLWAButtonClick}
        disabled={!selectedRegion || !amazonMarketplaceId}
        width="100%"
        margin="20px 0 0">
        {t(
          'mwsSync:AccountSync.lwa.authenticateAccountButton',
          'Authenticate Account Connection'
        )}
      </Button>
      <Button
        btnType={ButtonType.PRIMARY_TEXT}
        margin="8px auto"
        onClick={() =>
          window.open(
            `https://support.junglescout.com/hc/en-us/articles/360021110574-Adding-and-Syncing-your-Amazon-Seller-Central-Jungle-Scout-Accounts`,
            '_blank',
            'noreferrer'
          )
        }>
        {t('mwsSync:AccountSync.havingTrouble', 'Having Trouble?')}
      </Button>
      <PrivacyNote>
        <LockIconWrapper>
          <Icon name="LOCK" height="14px" width="14px" color="white" />
        </LockIconWrapper>
        <Text variant="bodyXs" color="grey600" element="div" margin="0 0 8px">
          {t(
            'account:SellerAccountSync.sync.privacyNote',
            'We take privacy and security extremely seriously. You sensitive data is encrypted using AES 256-bit encryption, the same level of encryption banks and governments use.'
          )}
        </Text>
        <Text variant="bodyXs" color="grey600" element="div">
          <Trans i18nKey="account:SellerAccountSync.sync.privacyTerms">
            Read our full{' '}
            <StyledLink
              href="https://www.junglescout.com/terms-of-use/"
              rel="noopener noreferrer"
              target="_blank">
              Terms of Service and Privacy Policy
            </StyledLink>{' '}
            for further information.
          </Trans>
        </Text>
      </PrivacyNote>
    </div>
  )
}

SellerAccountSync.defaultProps = {
  connectCallback: undefined,
  introWidth: undefined,
  regionsPadding: undefined
}

SellerAccountSync.propTypes = {
  connectCallback: PropTypes.func,
  introWidth: PropTypes.string,
  regionsPadding: PropTypes.string
}

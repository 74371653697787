import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Text } from '@junglescout/edna'

import { JSPRO_BUCKET } from 'constants/s3'

const IntroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`
const ImageWrapper = styled.img`
  display: block;
  width: 182px;
  height: 60px;
`
const StyledText = styled(Text)`
  max-width: ${({ width }) => width};
  ${({ increasedSize }) => increasedSize && 'max-width: 590px'};
`

export const SellerAccountSyncIntro = ({ increasedSize, width }) => {
  const { t } = useTranslation('account')

  return (
    <IntroWrapper>
      <ImageWrapper
        src={`${JSPRO_BUCKET}/images/account-icons/seller-account-sync.svg`}
        alt="Integrate Seller Central"
      />
      <Text
        variant={increasedSize ? 'headingLg' : 'headingSm'}
        align="center"
        element="h3">
        {t(
          'account:SellerAccountSync.intro.title',
          'Integrate Jungle Scout & Amazon Seller Central'
        )}
      </Text>
      <StyledText
        increasedSize={increasedSize}
        variant={increasedSize ? 'bodyLg' : 'bodySm'}
        width={width}
        color="grey600"
        align="center"
        element="div">
        {t(
          'account:SellerAccountSync.intro.description',
          'Gain deeper understanding into your businesses performance and get more reviews by integrating your Professional Amazon Seller Central account.'
        )}
      </StyledText>
    </IntroWrapper>
  )
}

SellerAccountSyncIntro.defaultProps = {
  increasedSize: false,
  width: '432px'
}

SellerAccountSyncIntro.propTypes = {
  increasedSize: PropTypes.bool,
  width: PropTypes.string
}

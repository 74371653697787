import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Modal } from '@junglescout/edna'

import { AMAZON_SELLER_KEYS_SYNC_STEPS } from 'constants/account'

import {
  showAccountSyncModal,
  closeAccountSyncModal
} from 'actions/account/sellerAccountSync'

import { WatchTutorial } from '../../common/junglekit/ui_elements/WatchTutorial/WatchTutorial'
import { SellerAccountSyncSteps } from './SellerAccountSyncSteps'

const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
`

export const SellerAccountSyncModal = () => {
  const { t } = useTranslation('account')
  const dispatch = useDispatch()

  const {
    ui: { activeModal }
  } = useSelector(state => state.sellerAccountSync)

  const isTutorialActive =
    activeModal === AMAZON_SELLER_KEYS_SYNC_STEPS.tutorialModal
  const isLoadingModal =
    activeModal === AMAZON_SELLER_KEYS_SYNC_STEPS.setupLoading

  const onTutorialClick = () => {
    dispatch(showAccountSyncModal(AMAZON_SELLER_KEYS_SYNC_STEPS.tutorialModal))
  }

  const renderTitle = () => {
    return (
      <ModalTitle>
        {t(
          'account:SellerAccountSync.modal.modalTitle',
          'Integrate Your Seller Central Account'
        )}
        {!isTutorialActive && (
          <WatchTutorial video="TODO" onClick={onTutorialClick} disableModal />
        )}
      </ModalTitle>
    )
  }

  if (!activeModal) {
    return null
  }

  return (
    <Modal
      open={!!activeModal || isLoadingModal}
      onOpenChange={() => {
        if (!isLoadingModal) {
          dispatch(closeAccountSyncModal())
        }
      }}>
      <Modal.Content
        minWidth={isTutorialActive ? '940px' : '565px'}
        title={renderTitle()}>
        <SellerAccountSyncSteps />
      </Modal.Content>
    </Modal>
  )
}

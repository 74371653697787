import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonType, Text } from '@junglescout/edna'

import { closeAccountSyncModal } from 'actions/account/sellerAccountSync'
import { redirectToSellerCentralAuthForm } from 'helpers/amazon_seller_account'
import { setLocalData } from 'helpers/storage'
import { LOCAL_STORAGE_KEYS } from 'constants/account'

import { UnableToConnect } from './common/UnableToConnect'
import { ConnectionFailedTag } from './common/ConnectionFailedTag'

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey100};
  border-radius: 8px;
  padding: 16px;
`
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`

export const SellerAccountError = () => {
  const { t } = useTranslation('generic')
  const dispatch = useDispatch()

  const { region, primaryMarketplaceId } = useSelector(
    state => state.sellerAccountSync.data
  )

  const handleTryAgainClick = () =>
    redirectToSellerCentralAuthForm({
      region,
      amazonMarketplaceId: primaryMarketplaceId
    })

  const handleFinishLaterClick = () => {
    setLocalData(LOCAL_STORAGE_KEYS.MWS_SYNC_REDIRECT_STATE, '{}')
    dispatch(closeAccountSyncModal())
  }

  return (
    <div>
      <Wrapper>
        <TitleWrapper>
          <Text>{t('generic:SellerCentral', 'Seller Central')}</Text>
          <ConnectionFailedTag />
        </TitleWrapper>
        <UnableToConnect
          connectLocation={t('generic:SellerCentral', 'Seller Central')}
        />
        <Button width="100%" margin="12px 0 0" onClick={handleTryAgainClick}>
          {t('generic:error.retry', 'Try Again')}
        </Button>
      </Wrapper>
      <Button
        btnType={ButtonType.TERTIARY}
        width="100%"
        margin="12px 0 0"
        onClick={handleFinishLaterClick}>
        {t('generic:FinishLater', 'Finish Later')}
      </Button>
    </div>
  )
}

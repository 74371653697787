import React from 'react'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { Text } from '@junglescout/edna'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.red050};
  border: 1px solid ${({ theme }) => theme.colors.red300};
  border-radius: 4px;
  padding: 12px;
  li {
    margin-left: 4px;
    span {
      margin-left: -4px;
    }
  }
`

const UnableToConnect = ({ connectLocation }) => {
  const { t } = useTranslation('account')

  return (
    <Wrapper>
      <Text variant="bodyXs" element="h3" margin="0 0 4px" fontWeight={500}>
        {t(
          'account:SellerAccountSync.modal.unableToConnect.title',
          'Unable to Connect to'
        )}{' '}
        {connectLocation}
      </Text>
      <Trans i18nKey="account:SellerAccountSync.modal.unableToConnect.description">
        <Text variant="bodyXs" element="p" color="grey600" margin="0 0 8px">
          Your connection attempt has failed. This issue may be caused by one of
          the following:
        </Text>
        <Text variant="bodyXs" element="p" color="grey600" margin="0 0 8px">
          <li>
            <span>
              You do not have Professional Seller Central account or your
              account is not fully upgraded.
            </span>
          </li>
          <li>
            <span>
              Your credit card information on Amazon Seller Central needs
              updating.
            </span>
          </li>
          <li>
            <span>
              There are account health issues affecting your Seller Central
              account.
            </span>
          </li>
        </Text>
        <Text variant="bodyXs" element="p" color="grey600">
          Please check these items, then try reconnecting. If the problem
          persists, contact Amazon Seller Support for further assistance.
        </Text>
      </Trans>
    </Wrapper>
  )
}

export { UnableToConnect }

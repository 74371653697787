import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AMAZON_SELLER_KEYS_SYNC_STEPS } from 'constants/account'
import { showAccountSyncModal } from 'actions/account/sellerAccountSync'

import { TutorialStep } from 'components/account/ConnectToMwsPpcSteps/TutorialStep'
import { SellerAccountError } from './SellerAccountError'
import { SellerAccountSync } from './SellerAccountSync'
import { SellerAccountSetup } from './SellerAccountSetup'
import { SellerAccountLoading } from './SellerAccountLoading'

export const SellerAccountSyncSteps = () => {
  const dispatch = useDispatch()
  const {
    ui: { activeModal, previousModal }
  } = useSelector(state => state.sellerAccountSync)

  switch (activeModal) {
    case AMAZON_SELLER_KEYS_SYNC_STEPS.tutorialModal:
      return (
        <TutorialStep
          hideSubtitle
          previousModal={previousModal}
          showMwsSyncModal={previousModal =>
            dispatch(showAccountSyncModal(previousModal))
          }
        />
      )
    case AMAZON_SELLER_KEYS_SYNC_STEPS.accountSync:
      return <SellerAccountSync />
    case AMAZON_SELLER_KEYS_SYNC_STEPS.accountSetup:
      return <SellerAccountSetup />
    case AMAZON_SELLER_KEYS_SYNC_STEPS.setupError:
      return <SellerAccountError />
    case AMAZON_SELLER_KEYS_SYNC_STEPS.setupLoading:
      return <SellerAccountLoading />
    default:
      return null
  }
}

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Icon, Text } from '@junglescout/edna'

const StyledTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  border: 1px solid ${({ theme }) => theme.colors.grey100};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.grey025};
  padding: 3px 6px;
`
const StyledIcon = styled(Icon)`
  background-color: ${({ theme }) => theme.colors.green600};
  border-radius: 50%;
  padding: 2px;
`

export const ConnectionSuccessTag = () => {
  const { t } = useTranslation('generic')

  return (
    <StyledTag>
      <StyledIcon name="CHECK" height="15px" width="15px" color="white" />
      <Text variant="bodyXs" color="grey600">
        {t('generic:Connected', 'Connected')}
      </Text>
    </StyledTag>
  )
}

import React from 'react'
import styled from 'styled-components'
import { LoadingPlaceholderGradient } from '@junglescout/edna'

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey100};
  border-radius: 8px;
  padding: 16px;
  min-width: 412px;
`
const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => marginBottom || '20px'};
`

export const SellerAccountLoading = () => {
  return (
    <div>
      <Wrapper>
        <RowWrapper>
          <LoadingPlaceholderGradient width="150px" height="22px" />
          <LoadingPlaceholderGradient width="68px" height="22px" />
        </RowWrapper>
        <RowWrapper>
          <LoadingPlaceholderGradient width="85px" height="20px" />
          <LoadingPlaceholderGradient width="85px" height="20px" />
        </RowWrapper>
        <RowWrapper marginBottom="5px">
          <LoadingPlaceholderGradient width="76px" height="20px" />
          <LoadingPlaceholderGradient width="73px" height="20px" />
        </RowWrapper>
        <LoadingPlaceholderGradient
          width="280px"
          height="32px"
          margin="0 0 20px"
        />
        <RowWrapper marginBottom="5px">
          <LoadingPlaceholderGradient width="76px" height="20px" />
          <LoadingPlaceholderGradient width="36px" height="20px" />
        </RowWrapper>
        <LoadingPlaceholderGradient width="280px" height="32px" />
      </Wrapper>
      <LoadingPlaceholderGradient
        width="100%"
        height="36px"
        margin="20px 0 0"
      />
    </div>
  )
}

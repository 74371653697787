import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Button,
  ButtonType,
  Text,
  FlagIcon,
  ToggleButton,
  Checkbox
} from '@junglescout/edna'

import {
  closeAccountSyncModal,
  setAmazonSellerAccountsData
} from 'actions/account/sellerAccountSync'
import {
  getUpdatedAmazonSellerAccounts,
  getUpdatedAmazonSellerAccountSelector
} from 'actions/review_automation'
import { createNotification } from 'actions/notifications'
import {
  MARKETPLACES,
  MARKETPLACES_REGIONS,
  REGIONS_WITH_COUNTRIES
} from 'constants/countries'
import { LOCAL_STORAGE_KEYS, PPC_SYNC_STATUS } from 'constants/account'
import { loadAmazonLoginSdk, loginWithAmazon } from 'helpers/ppc'
import { setLocalData } from 'helpers/storage'
import { sendCtaClickEvent } from 'helpers/segment'

import { ConnectionSuccessTag } from './common/ConnectionSuccessTag'
import { ConnectionFailedTag } from './common/ConnectionFailedTag'
import { UnableToConnect } from './common/UnableToConnect'

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey100};
  border-radius: 8px;
  padding: 16px;
  ${({ width }) => width && `width: ${width};`}
`
const AlignSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({ margin }) => `${margin || '0 0 16px'}`};
`
const StyledFlagIcon = styled(FlagIcon)`
  margin-left: -4px;
`
const StyledText = styled(Text)`
  max-width: 280px;
`
const MarketplacesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.grey100};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.grey050};
  padding: 12px;
  margin-top: 8px;
`
const CheckboxesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const SellerAccountSetup = ({ onNextStep, width }) => {
  const { t } = useTranslation([
    'generic',
    'account',
    'setup_wizard',
    'dashboard'
  ])
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const { sellerAccountId } = useSelector(state => state.sellerAccountSync.data)
  const ppcSyncStatus = useSelector(
    state => state.sellerAccountSync.ui.ppcSyncStatus
  )
  const { amazonSellerAccounts, amazonSellerAccountSelector } = useSelector(
    state => state.amazonSellerAccounts
  )

  const { countries: selectedAccountMarketplaces, id: newlyAddedAccountId } =
    amazonSellerAccounts[amazonSellerAccounts.length - 1] || {}

  // If the user connects their PPC account later (e.g., on the account page),
  // we should avoid overriding the initial Review Automation state.
  // The Review Automation toggle is off during the initial render, but if the user enables marketplaces during the setup,
  // we need to retain and reflect those selections when they connect their PPC account later.
  const isAnyMarketplaceEnabled = selectedAccountMarketplaces?.some(
    ({ review_automation_enabled }) => review_automation_enabled
  )

  const [toggleEnabled, setToggleEnabled] = useState(isAnyMarketplaceEnabled)
  const [enabledMarketplaces, setEnabledMarketplaces] = useState([])

  // There might be two sources of sellerAccountId, one from the account that is being edited from for ex. account page,
  // and one from the newly added account
  const amazonSellerAccountId = sellerAccountId || newlyAddedAccountId

  const accountData = amazonSellerAccounts.find(
    account => account.id === amazonSellerAccountId
  )

  const regionToUse = accountData?.region || 'na'
  const connectedMarketplaces =
    accountData?.countries?.map(m => m.country_code) || []

  // We want to force user to enable all marketplaces for all accounts, when we first render this modal,
  // so we set all checkboxes to checked by default
  const marketplacesData =
    selectedAccountMarketplaces?.map(({ country_code, marketplace_id }) => ({
      country_code,
      review_automation_enabled: true,
      amazon_seller_account_id: amazonSellerAccountId,
      id: marketplace_id
    })) || []

  useEffect(() => {
    loadAmazonLoginSdk()
  }, [])

  useEffect(() => {
    // If the user has enabled Review Automation for any marketplace before, we should show it in the modal
    if (isAnyMarketplaceEnabled) {
      setEnabledMarketplaces(selectedAccountMarketplaces)
    } else if (!enabledMarketplaces.length && marketplacesData.length) {
      setEnabledMarketplaces(marketplacesData)
    }
  }, [
    enabledMarketplaces.length,
    isAnyMarketplaceEnabled,
    marketplacesData,
    selectedAccountMarketplaces
  ])

  const { mutate: updateRAMarketplaces, isLoading } = useMutation(
    `api/junglekit/marketplaces/bulk`,
    {
      onSuccess: result => {
        const { marketplaces } = result.data

        sendCtaClickEvent({
          text: 'Review Automation Activated',
          location: 'Seller Account Setup',
          extraProps: {
            region: MARKETPLACES_REGIONS[regionToUse]
          }
        })

        // Update the amazonSellerAccounts with updated marketplaces API response
        const updatedAmazonSellerAccounts = getUpdatedAmazonSellerAccounts(
          amazonSellerAccounts,
          marketplaces
        )

        // Also update the amazonSellerAccountSelector with the new data
        const updatedAmazonSellerAccountSelector = getUpdatedAmazonSellerAccountSelector(
          amazonSellerAccountSelector,
          updatedAmazonSellerAccounts,
          marketplaces
        )

        dispatch(
          setAmazonSellerAccountsData(
            updatedAmazonSellerAccounts,
            updatedAmazonSellerAccountSelector
          )
        )

        dispatch(closeAccountSyncModal())
      },
      onError: ({ error }) => {
        dispatch(
          createNotification({
            message: error,
            level: 'error',
            title: t(
              'account:SellerAccountSetup.raToggleError',
              'Toggle Review Automation Error'
            )
          })
        )
      }
    }
  )

  const handleAmazonLogin = () => loginWithAmazon(amazonSellerAccountId)

  const renderAmazonAdsButton = () => {
    if (ppcSyncStatus === PPC_SYNC_STATUS.success) {
      return <ConnectionSuccessTag />
    }
    if (ppcSyncStatus === PPC_SYNC_STATUS.failure) {
      return <ConnectionFailedTag />
    }
    return (
      <Button mini onClick={handleAmazonLogin}>
        {t('generic:Connect', 'Connect')}
      </Button>
    )
  }

  const handleCheckboxClick = (selection, i) =>
    setEnabledMarketplaces(prevState => {
      const updatedMarketplacesSelection = [...prevState]
      updatedMarketplacesSelection[i].review_automation_enabled = selection

      return updatedMarketplacesSelection
    })

  const renderRaMarketplaces = () => {
    if (!toggleEnabled) {
      return null
    }

    return (
      <MarketplacesWrapper>
        <Text
          variant="bodyXs"
          color="grey600"
          fontWeight={500}
          margin="0 0 8px">
          {t(
            'setup_wizard:ReviewAutomationStep.disclaimer',
            'Review requests will be sent for these marketplaces'
          )}
          :
        </Text>
        <CheckboxesWrapper>
          {enabledMarketplaces.map(({ country_code }, i) => (
            <Checkbox
              id={country_code}
              key={country_code}
              label={MARKETPLACES[country_code.toLowerCase()].name}
              checked={enabledMarketplaces[i].review_automation_enabled}
              onChange={selection => handleCheckboxClick(selection, i)}
            />
          ))}
        </CheckboxesWrapper>
      </MarketplacesWrapper>
    )
  }

  // If the user is on the setup page, the action button should be 'Next', to proceed with extension setup
  const actionButtonName = pathname.includes('setup')
    ? t('generic:Next', 'Next')
    : t('generic:Finish', 'Finish')

  const handleFinishAction = () => {
    if (toggleEnabled) {
      updateRAMarketplaces({
        data: { marketplaces: enabledMarketplaces },
        method: 'PATCH'
      })
    } else {
      dispatch(closeAccountSyncModal())
    }
    // Reset localstorage
    setLocalData(LOCAL_STORAGE_KEYS.PPC_SYNC_REDIRECT_STATE, '{}')

    if (onNextStep) {
      onNextStep()
    }
  }

  const renderConnectedMarketplaces = () =>
    REGIONS_WITH_COUNTRIES[regionToUse.toLowerCase()].map(country => {
      if (!connectedMarketplaces.includes(country)) {
        return null
      }
      return <StyledFlagIcon key={country} size="20px" country={country} />
    })

  return (
    <div>
      <Wrapper width={width}>
        <AlignSpaceBetween>
          <Text variant="headingSm">{accountData?.label}</Text>
          <div>{renderConnectedMarketplaces()}</div>
        </AlignSpaceBetween>
        <AlignSpaceBetween margin="0 0 20px">
          <Text>{t('generic:SellerCentral', 'Seller Central')}</Text>
          <ConnectionSuccessTag />
        </AlignSpaceBetween>
        <AlignSpaceBetween margin="0">
          <Text>{t('generic:AmazonAds', 'Amazon Ads')}</Text>
          {renderAmazonAdsButton()}
        </AlignSpaceBetween>
        <StyledText
          element="div"
          variant="bodyXs"
          color="grey600"
          margin="0 0 8px">
          {t(
            'account:SellerAccountSetup.connectAmazonAdsPros',
            'Gain deeper understanding into your advertising performance by integrating your Amazon Ads Account'
          )}
        </StyledText>
        {ppcSyncStatus === PPC_SYNC_STATUS.failure && (
          <UnableToConnect
            connectLocation={t('generic:AmazonAds', 'Amazon Ads')}
          />
        )}
        <AlignSpaceBetween margin="20px 0 0">
          <Text>
            {t('dashboard:tools.reviewAutomation.title', 'Review Automation')}
          </Text>
          <ToggleButton
            disabled={isLoading}
            checked={toggleEnabled}
            handleToggleClick={setToggleEnabled}
            title="Toggle Review Automation"
          />
        </AlignSpaceBetween>
        <StyledText element="div" variant="bodyXs" color="grey600">
          {t(
            'account:SellerAccountSetup.connectReviewAutomationPros',
            'Sellers who enable Review Automation on average get 15% more product reviews than those who don’t.'
          )}
        </StyledText>
        {renderRaMarketplaces()}
      </Wrapper>
      <Button
        btnType={toggleEnabled ? ButtonType.PRIMARY : ButtonType.TERTIARY}
        disabled={isLoading}
        width="100%"
        margin="12px 0 0"
        onClick={handleFinishAction}>
        {actionButtonName}
      </Button>
    </div>
  )
}

SellerAccountSetup.defaultProps = {
  onNextStep: undefined,
  width: undefined
}

SellerAccountSetup.propTypes = {
  onNextStep: PropTypes.func,
  width: PropTypes.string
}
